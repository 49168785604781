<template>
  <div>
    <v-card elevation="2">
      <v-card-title>
        <div class="required-label">
          {{ $t("REVIEWS.TITLE", { campus: campus.name }) }}
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <h6>
              {{ $t("REVIEWS.DESCRIPTION1") }}
            </h6>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h6>
              {{ $t("REVIEWS.DESCRIPTION2") }}
            </h6>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h6>
              {{ $t("REVIEWS.DESCRIPTION3") }}
            </h6>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h6>
              {{ $t("REVIEWS.DESCRIPTION4") }}
            </h6>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="url"
              outlined
              readonly
              :label="$t('REVIEWS.URL')"
              type="text"
            >
              <template v-slot:append>
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  @click="copyUrlToClipboard()"
                >
                  {{ $t("REVIEWS.COPY_CLIPBOARD") }}
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Rules from "@/core/services/utils/validations";
import { Encrypt } from "@/core/services/utils/encryptService";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      emails: null,
      campusId: null,
      save_loading: false,
      help: true,
      url: "",
      // VALIDATIONS
      validations: {
        ...Rules,
      },
    };
  },
  methods: {
    copyUrlToClipboard() {
      navigator.clipboard.writeText(this.url);
      this.$bvToast.toast(this.$t("REVIEWS.URL_SUCCESS"), {
        title: this.$t("REVIEWS.URL"),
        variant: "success",
        solid: true,
      });
    },
  },
  created() {
    const encodedId = Encrypt("campus", this.campus.campusId);
    this.url = `${process.env.VUE_APP_PATH}review/${encodedId}`;
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("REVIEWS.REVIEW") },
    ]);
  },
  computed: {
    ...mapGetters(["reviews", "campus"]),
  },
};
</script>
